import {
  dateToAAAAMMDD,
  FbButtonState,
  FbDatepicker,
  FbFormDropdown,
  FbFormTextarea,
  FbModal,
  FbSpinner,
} from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions, RemoteDataFunctions } from '@decernointernal/websd.shared';
import { CreateJournalCmd, JournalListDTO, JournalPK, UpdateJournalCmd } from 'generated-models/budgetera';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { createJournalAction, removeJournalAction, selectCreateJournalCmd } from './cmdCreateJournal';
import { removeUpdateJournalAction, selectUpdateJournalCmd, updateJournalAction } from './cmdUpdateJournal';
import { getJournalTypQryAction, selectJournalTypQry } from './qryJournalTyp';

interface CreateOrEditJournalModalProps {
  initialJournalState?: JournalListDTO;
  onClose: () => void;
  kontorId: number;
}

interface JournalToCreateOrUpdate {
  JournalPK: JournalPK;
  JournalTypId?: number;
  Beskrivning?: string;
  SkapadDatum?: Date | null;
  SenastAndradDatum?: Date | null;
}

const getInitialJournalState = (): JournalToCreateOrUpdate => {
  return {
    JournalPK: { JournalId: undefined },
    JournalTypId: undefined,
    Beskrivning: undefined,
    SkapadDatum: undefined,
    SenastAndradDatum: undefined,
  };
};

type modalType = 'create' | 'update';

export const CreateOrEditJournalModal: React.FC<CreateOrEditJournalModalProps> = ({
  initialJournalState,
  onClose,
  kontorId,
}) => {
  const dispatch = useAppDispatch();
  const [journal, setJournal] = useState<JournalToCreateOrUpdate>(initialJournalState ?? getInitialJournalState());
  const initialSkapadDatum = initialJournalState?.SkapadDatum;
  const modalType: modalType = initialJournalState ? 'update' : 'create';
  const [buttonConfirmState, setButtonConfirmState] = useState<FbButtonState>('default');
  const createJournalCmd = useSelector(selectCreateJournalCmd);
  const updateJournalCmd = useSelector(selectUpdateJournalCmd);
  const journalTypQry = useSelector(selectJournalTypQry());

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(journalTypQry)) {
      dispatch(getJournalTypQryAction());
    }
  }, [dispatch, journalTypQry]);

  React.useEffect(() => {
    if (CommandFunctions.isSuccess(createJournalCmd) || CommandFunctions.isSuccess(updateJournalCmd)) {
      // Remove command from commandList
      dispatch(removeJournalAction());
      dispatch(removeUpdateJournalAction());
      onClose();
    }
    if (CommandFunctions.isFailure(createJournalCmd) || CommandFunctions.isFailure(updateJournalCmd)) {
      setButtonConfirmState('failure');
    }
    if (CommandFunctions.isExecuting(createJournalCmd) || CommandFunctions.isExecuting(updateJournalCmd)) {
      setButtonConfirmState('waiting');
    }
  }, [setButtonConfirmState, onClose, createJournalCmd, updateJournalCmd, dispatch]);

  React.useEffect(() => {
    // Remove focus from element when opening modal
    const timer = setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }, 1);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const createJournal = () => {
    if (!isJournalValid()) return;
    const cmd: CreateJournalCmd = {
      JournalTypId: journal.JournalTypId!,
      SkapadDatum: journal.SkapadDatum!,
      Beskrivning: journal.Beskrivning!,
      KontorId: kontorId,
    };
    dispatch(createJournalAction(cmd));
  };

  const updateJournal = () => {
    if (!isJournalValid()) return;
    const cmd: UpdateJournalCmd = {
      PrimaryKey: journal.JournalPK,
      JournalTypId: journal.JournalTypId!,
      SkapadDatum: journal.SkapadDatum!,
      Beskrivning: journal.Beskrivning!,
      KontorId: kontorId,
    };
    dispatch(updateJournalAction(cmd));
  };

  const isJournalValid = (): boolean => {
    if (journal.JournalTypId === undefined) return false;
    if (journal.Beskrivning === undefined || journal.Beskrivning === '') return false;
    if (journal.SkapadDatum === undefined) return false;
    return true;
  };

  return (
    <FbModal
      titel={(modalType === 'create' ? 'Skapa ny' : 'Redigera') + ' anteckning'}
      buttonCancelText="Avbryt"
      onCancel={() => {
        onClose();
      }}
      buttonConfirmText="Spara"
      onConfirm={() => {
        modalType === 'create' ? createJournal() : updateJournal();
      }}
      disabled={!isJournalValid()}
      confirmButtonState={buttonConfirmState}
      confirmButtonTooltipText={
        !isJournalValid() ? 'Anteckningen kan inte sparas förrän Typ, Datum och beskrivning är ifyllt' : undefined
      }
    >
      <div className="flex min-h-[430px] overflow-hidden justify-center">
        {RemoteDataFunctions.hasData(journalTypQry) ? (
          <>
            <div className="w-1/3 flex-col flex">
              <FbFormDropdown
                id="journalTyp"
                values={journalTypQry.data.map(jt => {
                  return { value: jt.JournalTypNamn, id: jt.JournalTypId };
                })}
                defaultId={journal.JournalTypId}
                label="Typ"
                onChange={value => {
                  setJournal({ ...journal, JournalTypId: Number(value?.id) });
                }}
                autoFocus={false}
              />
              <FbDatepicker
                id="journalDatePicker"
                label={'ÅÅÅÅ-MM-DD'}
                startDate={journal.SkapadDatum ?? undefined}
                onChange={(date: Date | null) => {
                  setJournal({ ...journal, SkapadDatum: date ?? undefined });
                }}
                onInstantChange={(value: string, isValid: boolean) => {
                  if (isValid) {
                    setJournal({ ...journal, SkapadDatum: new Date(value) });
                  }
                }}
              />
              {journal.JournalPK.JournalId && (
                <div className="mt-auto">
                  {journal.SenastAndradDatum && (
                    <p className="text-xs-increased">
                      Senaste ändring: {dateToAAAAMMDD(new Date(journal.SenastAndradDatum.toString()))}
                    </p>
                  )}
                  {initialSkapadDatum && (
                    <p className="text-xs-increased pt-1">Skapad: {dateToAAAAMMDD(initialSkapadDatum)}</p>
                  )}
                </div>
              )}
            </div>
            <div className="w-2/3 pl-4">
              <FbFormTextarea
                id="JournalBeskrivning"
                label="Beskrivning"
                className="!mt-4 h-[96%]"
                defaultValue={journal.Beskrivning}
                onInstantChange={value => setJournal({ ...journal, Beskrivning: value })}
                autoFocus={false}
              />
            </div>
          </>
        ) : (
          <div className="self-center">
            <FbSpinner size="large" />
          </div>
        )}
      </div>
    </FbModal>
  );
};
